<template>
  <FeatherIcon type="circle" size="10px" stroke="#10b759" fill="#10b759" v-if="online" />
  <FeatherIcon type="circle" size="10px" stroke="#7987a1" fill="#7987a1" v-else />
</template>

<script>
export default {
  name: 'Status',
  props: {
    online: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
